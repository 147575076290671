<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false">
    <mat-toolbar><a [routerLink]="['/dashboard']"
        class="main_logo"><img src="./assets/images/Helvetic Logo.jpg" alt="Helvetic Logo"/></a>
    </mat-toolbar>
    <mat-nav-list>
      <a mat-list-item [routerLink]="['/dashboard']" routerLinkActive="router-link-active">Contracts</a>
      <a mat-list-item [routerLink]="['/fleet']" routerLinkActive="router-link-active">Fleet</a>
      <a mat-list-item [routerLink]="['/reports']" routerLinkActive="router-link-active">Reports</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>        
      </button>
      <app-header [isHandset]="isHandset$ | async"></app-header>
    </mat-toolbar>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>